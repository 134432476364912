import React, { MouseEventHandler, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { GuideTextBoxForGeneral } from '../../../Component/TextBox';

import './Styles/ApplicationForm.scss';
import WithLabel from '../../../Component/WithLabel';
import { GuideTextBoxForStandAloneVerification } from '../../../Component/TextBox';
import { GuideTextBoxForPairedVerification } from '../../../Component/TextBox';
import TextCheckBox from '../../../Component/TextCheckBox';
import RadioGroup from './RadioGroup';
import SingleUploadImageDisplayed from './SingleUploadImageDisplayed';
import MultiUploadFilenameDisplayed from './MultiUploadFilenameDisplayed';
import MultiUploadImageDisplayed from './MultiUploadImageDisplayed';
import { readProfile } from '../../../Data/Profile';
import { verifyEmail, verifyOtp } from '../../../Data/Email';
import { readLoginInfo } from '../../../Data/Account';
import { useSession, useSignIn } from '../../../Recoil/Session/Session';
import { getTitle } from '../../../Data/Title';
import Disclaimer from './Disclaimer';
import { randomString, sortFunc } from '../../../Functions';
import { getThumbnail } from '../../../Data/Image';
import { useToastAlert } from '@webtoontoday/toast';
import { Warning } from '@material-ui/icons';

const maxLength = 25;

const stepperContent = [['AnalysisApplicationForm0','기본 정보'], ['AnalysisApplicationForm1','작품 정보'], ['AnalysisApplicationForm2','회차 원고'], ['AnalysisApplicationForm3','광고 이미지']];
const stepperHeight = 126;
const recognitionArea = stepperHeight + 25;
const recognitionAreaForLast = 165;

const serviceOptions: {value: "competition" | "analytics", label: ReactNode}[] = [
    {
        value: "competition", label: "공모전(창작 지원금 50만원) + 데이터 분석 "
    },{
        value: "analytics", label: "웹툰 디렉터의 상세한 피드벡 + 데이터 분석 "
    }
]

const ApplicationForm = () => {
    const { toastAlert } = useToastAlert();
    const { session } = useSession();
    const { EmailTokenSignin } = useSignIn();

    const [ hasTriedApplying, setHasTriedApplying ] = useState<boolean>(false);
    const [ canSpeechBalloonVisible, setCanSpeechBalloonVisible ] = useState<boolean>(true);

    const [ agreements, setAgreements ] = useState<agreementType>({
        serviceProvisionAgreement: false,
        marketingAgreement: false,
        webtoonCopyingAgreement: false,
        analysisCopyingAgreement: false,
        platformUploadAgreement: false,
    })
    const [ analysisApplicationData, setAnalysisApplicationData ] = useState<analysisApplicationDataType>({
        // BasicInfo
        serviceType: 'competition',
        nameKor: '',
        addressid: randomString(16),
        addressidValidationStatus: 'undone',
        email: '',
        emailValidationStatus: 'undone',
        emailKey: '',
        emailKeyValidationStatus: 'undone',
        //WebtoonInfo
        title: '',
        titleId: randomString(16),
        titleIdValidationStatus: 'undone',
        isAdult: false,
        representativeImage: [],
        //EpisodeInfo
        episodeInfo: [
            {
                manuscripts: [],
                thumbnail: [],
                isAutoThumbnail: false
            }
        ],
        //adCutInfo
        adCuts: [],
        isAutoAdCut: false
    })
    const [ isMoreEpisode, setIsMoreEpisode ] = useState<boolean>(false);
    const [ isInfoCompleted, setIsInfoCompleted ] = useState<boolean>(false);

    const {
        serviceType, nameKor, addressidValidationStatus, emailValidationStatus, emailKeyValidationStatus,
        title, titleIdValidationStatus, representativeImage,
        episodeInfo,
        adCuts, isAutoAdCut
    } = analysisApplicationData

    const basicInfoStatus = 
           serviceType.length > 0 
        && nameKor.length > 0 
        && addressidValidationStatus === 'success' 
        && emailValidationStatus === 'success' 
        && emailKeyValidationStatus === 'success'
            ? 'success'
            : hasTriedApplying
                ? 'caution'
                : 'undone'
    
    const webtoonInfoStatus = 
           title.length > 0
        && titleIdValidationStatus === 'success'
        && representativeImage.length > 0
            ? 'success'
            : hasTriedApplying
                ? 'caution'
                : 'undone'

    const { manuscripts, thumbnail, isAutoThumbnail } = episodeInfo[0];
    
    const episodeInfoStatus = 
           manuscripts.length > 0
        && (thumbnail[0]?.name.length > 0 || isAutoThumbnail )
        && (thumbnail[0]?.image.length > 0 || isAutoThumbnail )
            ? 'success'
            : hasTriedApplying
                ? 'caution'
                : 'undone'
    
    const adCutInfoStatus = 
        (adCuts.length > 0 || isAutoAdCut)
            ? 'success'
            : hasTriedApplying
                ? 'caution'
                : 'undone'

    const validationStatuses: ('success' | 'caution' | 'undone')[] = [basicInfoStatus, webtoonInfoStatus, episodeInfoStatus, adCutInfoStatus];
    
    const [currentSections, setCurrentSections] = useState<string[]>([]);
    const [viewHeight, setViewHeight] = useState<number>(window.innerHeight);
    const hasLastSectionPassed = useRef<boolean>(true);
    
    const currentSection = currentSections[currentSections.length - 1];

    const handleClick = useCallback((id: string) => {
        if(id === currentSection) return;
        const element = document.getElementById(id);
        if(!element) return;

        element.scrollIntoView({behavior: 'smooth'});
        const scrollAmount = -(stepperHeight - element.getBoundingClientRect().top);
        window.scrollBy({top: scrollAmount, behavior: 'smooth'});
    }, [currentSection]);

    useEffect(() => {
        if(isInfoCompleted){
            return;
        }
        // for components except the last one
        const options = {
            rootMargin: `${-stepperHeight}px 0px ${-viewHeight + recognitionArea}px 0px`
        }
        const handleIntersection = (entries: IntersectionObserverEntry[]) => {
            entries.forEach(entry => {
                if(entry.isIntersecting){
                    setCurrentSections(prevSections => [...prevSections, entry.target.id]);
                } else{
                    setCurrentSections(prevSections => prevSections.filter(prevSection => prevSection !== entry.target.id));
                }
            })
        }

        const observer = new IntersectionObserver(handleIntersection, options);
        for(let i = 0; i < stepperContent.length; i++){
            const element = document.getElementById(stepperContent[i][0]);
            if(element){
                observer.observe(element);
            }
        }

        // for the last component
        const optionsForLast = {
            rootMargin: `${-viewHeight + recognitionAreaForLast}px 0px 0px 0px`
        }
        const handleIntersectionForLast = (entries: IntersectionObserverEntry[]) => {
            const entry = entries[0];
            if(hasLastSectionPassed.current){
                if(entry.isIntersecting){
                    setCurrentSections(prevSections => prevSections.filter((prevSection, index, prevSections) => !(prevSection === entry.target.id && index === prevSections.length - 1)));
                    hasLastSectionPassed.current = false;
                }
            } else{
                if(!entry.isIntersecting && entry.boundingClientRect.top < viewHeight){
                    setCurrentSections(prevSections => [...prevSections, entry.target.id]);
                    hasLastSectionPassed.current = true;
                }
            }
        }

        const observerForLast = new IntersectionObserver(handleIntersectionForLast, optionsForLast);
        const lastElement = document.getElementById(stepperContent[stepperContent.length - 1][0]);
        if(lastElement){
            observerForLast.observe(lastElement);
        }

        return () => {
            for(let i = 0; i < stepperContent.length; i++){
                const element = document.getElementById(stepperContent[i][0]);
                if(element){
                    observer.unobserve(element);
                }
            }
            const lastElement = document.getElementById(stepperContent[stepperContent.length - 1][0]);
            if(lastElement){
                observerForLast.unobserve(lastElement);
            }
        }
    }, [viewHeight, isInfoCompleted])

    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout>;

        const handleResize = () => {
            clearTimeout(timeoutId);
            
            timeoutId = setTimeout(() => {
                setViewHeight(window.innerHeight);
            }, 300);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if(session.memberState !== 'regular'){
            return;
        }
        const sessionAddressid = session.addressid || '';
        const sessionEmail = session.email || '';
        setAnalysisApplicationData(prev => ({...prev,
            addressid: sessionAddressid,
            addressidValidationStatus: 'success',
            email: sessionEmail,
            emailValidationStatus: 'success',
            emailKeyValidationStatus: 'success'
        }));
    }, [session.addressid, session.email, session.memberState])
    
    return (
        <div className={'ApplicationForm'} >
            {Date.now() >= (new Date("2025-02-28 23:59:59+09:00")).getTime() &&
                <div className={"ServiceEndDisclaimer"}><Warning />{"데이터 분석 신청/월간 공모전 운영이 2025년 2월 28일자로 종료되었습니다."}</div>}
            {!isInfoCompleted
            ?<>
                <Stepper
                    currentSection={currentSection}
                    stepperContent={stepperContent}
                    handleClick={handleClick}
                    validationStatuses={validationStatuses}
                />
                <div className={'Form'}>
                    <div 
                        id={stepperContent[0][0]}
                        className={'BasicInfo'}
                        onClick={() => handleClick(stepperContent[0][0])}
                    >
                        <SectionHeader content={`1. ${stepperContent[0][1]}`} />
                        <WithLabel label={'서비스 유형'}>                            
                            <RadioGroup<"competition"|"analytics">
                                value={analysisApplicationData.serviceType} fullWidth
                                options={serviceOptions}
                                onChange={(value) => setAnalysisApplicationData(prev => ({...prev, serviceType: value}))}
                            />
                        </WithLabel>
                        <hr className={'InfoLine'} />
                        <WithLabel label={'이메일'}>
                            <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                <GuideTextBoxForPairedVerification
                                    purpose={'send'}
                                    text={analysisApplicationData.email}
                                    validationPattern={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})*$/}
                                    placeholder={'이메일을 입력해주세요.'}
                                    onChange={(e) => {
                                        const newText = e.target.value.trim();
                                        setAnalysisApplicationData(prev => ({...prev, email: newText, emailKey: '', emailValidationStatus: 'undone', emailKeyValidationStatus: 'undone'}));
                                    }}
                                    guideTexts={{
                                        initial: '',
                                        normal: '',
                                        success: '인증되었어요.',
                                        fail: '',
                                        required: '인증을 완료해주세요.'
                                    }}
                                    validationStatus={analysisApplicationData.emailValidationStatus}
                                    secondStepValidationStatus={analysisApplicationData.emailKeyValidationStatus}
                                    onClick={() => {
                                        verifyEmail(analysisApplicationData.email);
                                        setAnalysisApplicationData(prev =>({...prev, emailValidationStatus: 'success'}))
                                    }}
                                    isDisabled={session.memberState === 'regular'}
                                    forcedGuideTextType={hasTriedApplying && analysisApplicationData.emailValidationStatus === 'undone' ? 'required' : undefined}
                                />
                                {( analysisApplicationData.emailValidationStatus === 'success' && analysisApplicationData.emailKeyValidationStatus !== 'success') &&  
                                    <GuideTextBoxForStandAloneVerification
                                        purpose={'verification'}
                                        text={analysisApplicationData.emailKey}
                                        placeholder={'인증 키를 입력하세요'}
                                        onChange={(e) => {
                                            const newText = e.target.value.trim();
                                            
                                            setAnalysisApplicationData(prev => ({...prev, emailKey: newText, emailKeyValidationStatus: 'undone'}));
                                        }}
                                        guideTexts={{
                                            initial: '',
                                            normal: '',
                                            success: '',
                                            fail: '인증키를 다시 확인해주세요.',
                                            required: '인증을 완료해주세요.'
                                        }}
                                        validationStatus={analysisApplicationData.emailKeyValidationStatus}
                                        onClick={async () => {
                                            setAnalysisApplicationData(prev =>({...prev, emailKeyValidationStatus: 'pending'}));

                                            const {isProfileExist} = await readLoginInfo(analysisApplicationData.email);

                                            if(isProfileExist){
                                                const response = await EmailTokenSignin(analysisApplicationData.email, analysisApplicationData.emailKey);

                                                if(response){
                                                    setAnalysisApplicationData(prev =>({...prev, emailKeyValidationStatus: 'success', titleIdValidationStatus: 'undone'}));
                                                } else{
                                                    setAnalysisApplicationData(prev =>({...prev, emailKeyValidationStatus: 'fail'}));
                                                }
                                            } else{
                                                const response = await verifyOtp(analysisApplicationData.email, analysisApplicationData.emailKey);
                                                if(response){
                                                    setAnalysisApplicationData(prev =>({...prev, emailKeyValidationStatus: 'success'}));
                                                } else{
                                                    setAnalysisApplicationData(prev =>({...prev, emailKeyValidationStatus: 'fail'}));
                                                }
                                            }
                                        }}
                                        forcedGuideTextType={hasTriedApplying && analysisApplicationData.emailKeyValidationStatus === 'undone' ? 'required' : undefined}
                                    />}
                            </div>
                        </WithLabel>
                        <WithLabel label={'이름 / 필명'}>
                            <GuideTextBoxForGeneral
                                text={analysisApplicationData.nameKor}
                                placeholder={'이름을 입력해주세요.'}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    
                                    if (maxLength >= 0) {
                                        setAnalysisApplicationData(prev => ({...prev, nameKor: value.slice(0, maxLength)}));
                                    } else {
                                        setAnalysisApplicationData(prev => ({...prev, nameKor: value}));
                                    }
                                }}
                                onBlur={(e) => {
                                    const value = e.target.value.trim();
                                    
                                    if (maxLength >= 0) {
                                        setAnalysisApplicationData(prev => ({...prev, nameKor: value.slice(0, maxLength)}));
                                    } else {
                                        setAnalysisApplicationData(prev => ({...prev, nameKor: value}));
                                    }
                                }}
                                guideTexts={{
                                    initial: '',
                                    normal: '',
                                    success: '',
                                    fail: '',
                                    required: '필수 입력 정보입니다.'
                                }}
                                isRequired={true}
                                maxLength={maxLength}
                                forcedGuideTextType={hasTriedApplying && analysisApplicationData.nameKor.length === 0 ? 'required' : undefined}
                            />
                        </WithLabel>
                        <WithLabel label={'이름 / 필명 (영문) '} >
                            <GuideTextBoxForStandAloneVerification
                                purpose={'uniqueness'}
                                text={analysisApplicationData.addressid}
                                placeholder={'작가 이름을 입력하세요'}
                                onChange={(e) => {
                                    if (/[^a-zA-Z0-9_]/g.test(e.target.value)){
                                        return;
                                    }

                                    const newText = e.target.value.trim();
                                    setAnalysisApplicationData(prev => ({...prev, addressid: newText}));
                                    setAnalysisApplicationData(prevState =>({...prevState, addressidValidationStatus: 'undone'}));
                                }}
                                guideTexts={{
                                    initial: '랜덤으로 생성된 이름이에요. 수정 할 수 있어요.',
                                    normal: '랜덤으로 생성된 이름이에요. 수정 할 수 있어요.',
                                    success: '사용할 수 있는 이름이에요.',
                                    fail: '중복되지 않는 이름을 입력해주세요.',
                                    required: '중복 확인이 필요해요.'
                                }}
                                validationStatus={analysisApplicationData.addressidValidationStatus}
                                onClick={async () => {
                                    setAnalysisApplicationData(prevData =>({...prevData, addressidValidationStatus: 'pending'}));
                                    
                                    const res = await readProfile(analysisApplicationData.addressid);
                                    if(res.addressid){
                                        setAnalysisApplicationData(prevData =>({...prevData, addressidValidationStatus: 'fail'}));
                                    } else{
                                        setAnalysisApplicationData(prevData =>({...prevData, addressidValidationStatus: 'success'}));
                                    }
                                }}
                                maxLength={maxLength}
                                isDisabled={session.memberState === 'regular'}
                                forcedGuideTextType={hasTriedApplying && analysisApplicationData.addressidValidationStatus === 'undone' ? 'required' : undefined}
                            />
                        </WithLabel>
                    </div>
                    <div 
                        id={stepperContent[1][0]}
                        className={'WebtoonInfo'}
                        onClick={() => handleClick(stepperContent[1][0])}
                    >
                        <SectionHeader content={`2. ${stepperContent[1][1]}`} />
                        <WithLabel label={'작품 제목'}>
                            <GuideTextBoxForGeneral
                                text={analysisApplicationData.title}
                                placeholder={'작품의 제목을 입력해주세요.'}
                                onChange={(e) => {
                                    const value = e.target.value;

                                    if (maxLength >= 0) {
                                        setAnalysisApplicationData(prev => ({...prev, title: value.slice(0, maxLength)}));
                                    } else {
                                        setAnalysisApplicationData(prev => ({...prev, title: value}));
                                    }
                                }}
                                onBlur={(e) => {
                                    const value = e.target.value.trim();

                                    if (maxLength >= 0) {
                                        setAnalysisApplicationData(prev => ({...prev, title: value.slice(0, maxLength)}));
                                    } else {
                                        setAnalysisApplicationData(prev => ({...prev, title: value}));
                                    }
                                }}
                                guideTexts={{
                                    initial: '',
                                    normal: '',
                                    success: '',
                                    fail: '',
                                    required: '필수 입력 정보입니다.'
                                }}
                                isRequired={true}
                                maxLength={maxLength}
                                forcedGuideTextType={hasTriedApplying && analysisApplicationData.title.length === 0 ? 'required' : undefined}
                            />
                        </WithLabel>
                        <WithLabel label={'작품 제목 (영문)'} >
                            <GuideTextBoxForStandAloneVerification
                                purpose={'uniqueness'}
                                text={analysisApplicationData.titleId}
                                placeholder={'작품 제목을 입력하세요'}
                                onChange={(e) => {
                                    if (/[^a-zA-Z0-9_]/g.test(e.target.value)){
                                        return;
                                    }
                                    const newText = e.target.value.trim();
                                    
                                    if (maxLength >= 0) {
                                        setAnalysisApplicationData(prev => ({...prev, titleId: newText.slice(0, maxLength), titleIdValidationStatus: 'undone'}));
                                    } else {
                                        setAnalysisApplicationData(prev => ({...prev, titleId: newText, titleIdValidationStatus: 'undone'}));
                                    }
                                }}
                                guideTexts={{
                                    initial: '랜덤으로 생성된 제목이에요. 수정 할 수 있어요.',
                                    normal: '랜덤으로 생성된 제목이에요. 수정 할 수 있어요.',
                                    success: '사용할 수 있는 제목이에요.',
                                    fail: '중복되지 않는 제목을 입력해주세요.',
                                    required: '중복 확인이 필요해요.'
                                }}
                                validationStatus={analysisApplicationData.titleIdValidationStatus}
                                onClick={async () => {
                                    setAnalysisApplicationData(prevData =>({...prevData, titleIdValidationStatus: 'pending'}));

                                    const searchedTitles = await getTitle(analysisApplicationData.addressid, analysisApplicationData.titleId);
                                    if(searchedTitles.length > 0){
                                        setAnalysisApplicationData(prevData =>({...prevData, titleIdValidationStatus: 'fail'}));
                                    } else{
                                        setAnalysisApplicationData(prevData =>({...prevData, titleIdValidationStatus: 'success'}));
                                    }
                                }}
                                maxLength={maxLength}
                                forcedGuideTextType={hasTriedApplying && analysisApplicationData.titleIdValidationStatus === 'undone' ? 'required' : undefined}
                            />
                        </WithLabel>
                        <WithLabel label={'성인 컨텐츠 여부'} >
                            <TextCheckBox
                                text={"성인 컨텐츠에요."}
                                isChecked={analysisApplicationData.isAdult}
                                onChange={ (e) => setAnalysisApplicationData( prev => ({...prev, isAdult: !prev.isAdult}) ) }
                                style={{width: '100%'}}
                            />
                        </WithLabel>
                        <WithLabel label={'대표 이미지'} >
                            <SingleUploadImageDisplayed 
                                width={110} height={110}
                                files={analysisApplicationData.representativeImage}
                                setFiles={(files) => setAnalysisApplicationData( prev => ({...prev, representativeImage: files}))}
                                screenSubtitle={["600px * 600px"]}
                            />
                        </WithLabel>
                    </div>
                    <div 
                        id={stepperContent[2][0]}
                        className={'ManuscriptInfoContainer'}
                        onClick={() => handleClick(stepperContent[2][0])} 
                    >
                        {episodeInfo.map( (episode, idx) => (
                            <div className={'ManuscriptInfo'} key={idx}>
                                {idx === 0 && <SectionHeader content={`3. ${stepperContent[2][1]}`} />}
                                <WithLabel label={`${idx + 1}화 원고`} >
                                    <MultiUploadFilenameDisplayed
                                        screenTitle={"마우스로 파일을 끌어서 넣을 수 있어요."}
                                        screenSubtitle={["이미지 파일(JPG 나 PNG) 혹은 ZIP 파일", "가로 690px 이상"]}
                                        files={analysisApplicationData.episodeInfo[idx].manuscripts}
                                        setFiles={(files) => setAnalysisApplicationData( prev => {
                                            let newEpisodeInfo = [...prev.episodeInfo];
                                            newEpisodeInfo[idx].manuscripts = files;
                                            const isAllLoaded = !newEpisodeInfo[idx].manuscripts.find( imageObj => !imageObj.image.includes("https://static.webtoon.today") );

                                            if ( isAllLoaded && files.length > 0 ) {
                                                newEpisodeInfo[idx].manuscripts = files.sort( (a,b) => sortFunc(a.image, b.image) );
                                            }

                                            return {...prev, episodeInfo: newEpisodeInfo};
                                        })}
                                        isItemDraggable={false}
                                    />
                                </WithLabel>
                                <WithLabel label={`${idx + 1}화 썸네일`} >
                                    <SingleUploadImageDisplayed 
                                        width={217} height={110}
                                        screenSubtitle={["이미지 파일(JPG 나 PNG)", "202px * 102px"]}
                                        files={analysisApplicationData.episodeInfo[idx].thumbnail}
                                        setFiles={(files) => setAnalysisApplicationData( prev => {
                                            const newEpisodeInfo = [...prev.episodeInfo];
                                            newEpisodeInfo[idx].thumbnail = files;
                                            newEpisodeInfo[idx].isAutoThumbnail = false;
                                            return {...prev, episodeInfo: newEpisodeInfo};
                                        })}
                                    >
                                        <>
                                            <TextCheckBox
                                                disabled={!analysisApplicationData.episodeInfo[idx]?.manuscripts[0]?.image}
                                                text={"원고에서 자동 등록"}
                                                isChecked={analysisApplicationData.episodeInfo[idx].isAutoThumbnail}
                                                onChange={ (e) => {
                                                    setAnalysisApplicationData(prev => {
                                                        const newEpisodeInfo = [...prev.episodeInfo];
                                                        newEpisodeInfo[idx].isAutoThumbnail = !newEpisodeInfo[idx].isAutoThumbnail;
                                                        return {...prev, episodeInfo: newEpisodeInfo};
                                                    });
                                                    ( async () => {
                                                        const episodeImages = analysisApplicationData.episodeInfo[idx]?.manuscripts;
                                                        if ( e.target.checked && episodeImages.length > 0 ) {
                                                            const generatedTitleThumbnail = await getThumbnail(episodeImages.map(({image}) => image), 600);

                                                            if (generatedTitleThumbnail) {
                                                                setAnalysisApplicationData(prev => {
                                                                    let newApplicationData = {...prev}
                                                                    newApplicationData.episodeInfo[idx].thumbnail = [{
                                                                        name: `${idx}화자동생성${analysisApplicationData.addressid}-${analysisApplicationData.titleId}`,
                                                                        image: generatedTitleThumbnail[0],
                                                                        createdAt: Math.floor((new Date()).getTime() / 1000)
                                                                    }];
                                                                    return newApplicationData;
                                                                })
                                                            }
                                                        }  
                                                    })()
                                                }}
                                                style={{
                                                    marginLeft: 'auto', 
                                                    gap: 8, padding: 0, borderWidth: 0,
                                                    fontSize: '0.875rem',
                                                    ...(!analysisApplicationData.episodeInfo[0]?.manuscripts[0]?.image?{}:{color: 'rgb(189, 189, 194)'}),
                                                    backgroundColor: 'rgb(255,255,255)'
                                                }}
                                                className={'Small'}
                                            />
                                        </>
                                    </SingleUploadImageDisplayed>
                                </WithLabel>
                            </div>
                        ))}
                    </ div>
                    <div className={`AdditionalEpisodeButton ${isMoreEpisode?'Cancel':''}`} 
                        onClick = {() => setAnalysisApplicationData(prev => {
                            setCanSpeechBalloonVisible(false);
                            if ( !isMoreEpisode ) {
                                setIsMoreEpisode(true);
                                return ({ ...prev, episodeInfo: [
                                    ...prev.episodeInfo, 
                                    {   
                                        manuscripts: [],
                                        thumbnail: [],
                                        isAutoThumbnail: false 
                                    }, {   
                                        manuscripts: [],
                                        thumbnail: [],
                                        isAutoThumbnail: false 
                                    },
                                ] });
                            } else {
                                setIsMoreEpisode(false);
                                return ({ ...prev, episodeInfo: [ prev.episodeInfo[0] ] });
                            }
                        })}
                    >
                        { !isMoreEpisode && <img src={'https://static.webtoon.today/ddah/icon/icon_add.svg'} alt={'icon_add'} height={'100%'} /> }
                        <div>
                            {!isMoreEpisode
                                ?<div className={'EpisodeMoreText'} >{'추가 회차분 업로드하기'}</div>
                                :<div className={'CancelEpisodeMoreText'} >{'추가 업로드 취소'}</div>
                            }
                            {episodeInfoStatus === 'success' && canSpeechBalloonVisible &&
                            <div className={'SpeechBalloon'} >
                                <img src={'https://static.webtoon.today/ddah/speech_balloon.png'} alt={'speech_balloon'} width={'100%'} />
                                <div className={'BalloonContent'} >{'회차를 등록하고 더 유용한 분석 결과를 받으세요!'}</div>
                            </div>}
                        </div>
                    </div>
                    <div 
                        id={stepperContent[3][0]}
                        className={'AdCutInfo'}
                        onClick={() => handleClick(stepperContent[3][0])} 
                    >
                        <SectionHeader content={`4. ${stepperContent[3][1]}`} />
                        <WithLabel label={'광고 이미지'} >
                            <MultiUploadImageDisplayed 
                                gridItemCounts={5}
                                screenTitle={"마우스로 파일을 끌어서 넣을 수 있어요."}
                                screenSubtitle={["이미지 파일(JPG 나 PNG) 혹은 ZIP 파일", "600px * 600px"]}
                                files={analysisApplicationData.adCuts}
                                setFiles={ (files: imageType[]) => setAnalysisApplicationData(prev => ({...prev, adCuts: files}) )}
                            >
                                <>
                                    <TextCheckBox
                                        text={"등록된 원고에서 자동 설정할게요."}
                                        isChecked={analysisApplicationData.isAutoAdCut}
                                        onChange={ (e) => setAnalysisApplicationData( prev => ({...prev, isAutoAdCut: !prev.isAutoAdCut}) ) }
                                        style={{
                                            width: '100%',
                                            gap: 8, padding: 0, borderWidth: 0,
                                            fontSize: '0.875rem',
                                            color: 'rgb(189, 189, 194)',
                                            backgroundColor: 'rgb(255,255,255)'
                                        }}
                                        className={'Small'}
                                    />
                                </>
                            </MultiUploadImageDisplayed>
                        </WithLabel>
                    </div>
                    <hr className={'HorizontalLine'} />
                    <div className={'ButtonArea'}>
                        <div className={'ApplyButton'} onClick={ () => {
                            if (
                                Date.now() >= new Date("2025-02-28 23:59:59+09:00").getTime()
                            ) {
                                toastAlert("데이터 분석 신청/공모전 운영이 2025년 2월 28일자로 종료되었습니다.");
                                return;
                            }
                            if (   basicInfoStatus === 'success'
                                && webtoonInfoStatus === 'success'
                                && episodeInfoStatus === 'success'
                                && adCutInfoStatus === 'success' ) {
                                setIsInfoCompleted(true);
                            } else {
                                setIsInfoCompleted(false);
                                toastAlert("모든 필수정보를 입력해 주세요.")
                            }
                            setHasTriedApplying(true);
                        }} >
                            <div className={`ButtonName`}>
                                {'약관 동의 후 신청하기'}
                            </div>
                            <img src={'https://static.webtoon.today/ddah/icon/icon_shevronRight.svg'} alt={'chevronRight'} style={{color: 'rgb(255,255,255)' }}/>
                        </div>
                    </div>
                </div>
            </>
            :<Disclaimer 
                agreements={agreements} 
                setAgreements={(agreements) => setAgreements(agreements)}
                goToPreviousPage={() => {
                    setCurrentSections([]);
                    setIsInfoCompleted(false);
                }}
                analysisApplicationData={analysisApplicationData}
            />}
        </div>
    );
};

const Stepper = ({currentSection, stepperContent, handleClick, validationStatuses}: {
    currentSection: string,
    stepperContent: string[][],
    handleClick: Function,
    validationStatuses: ('success' | 'caution' | 'undone')[]
}) => {
    return (
        <div className={'Stepper'}>
            {stepperContent.map(([id, category], index) => (
                <Step
                    key={id}
                    isCurrentSection={currentSection === stepperContent[index][0]}
                    ordinalNumber={index + 1}
                    content={category}
                    validationStatus={validationStatuses[index]}
                    onClick={() => handleClick(id)}
                />
            ))}
        </div>
    );
}

const stepStatusToIcon = {
    'undone': 'data:image/svg+xml;charset=utf-8,<svg width="1" height="1" viewBox="0 0 1 1" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>',
    'caution': 'https://static.webtoon.today/ddah/icon/icon_caution.svg',
    'success': 'https://static.webtoon.today/ddah/icon/icon_success.svg'
}
const Step = ({isCurrentSection, ordinalNumber, content, validationStatus, onClick}: {
    isCurrentSection: boolean,
    ordinalNumber: number,
    content: string,
    validationStatus: 'undone' | 'caution' | 'success',
    onClick: MouseEventHandler<HTMLDivElement>
}) => {
    return (
        <div
            className={`Step${isCurrentSection ? ' Current': ''}`}
            onClick={onClick}
        >
            <span>{ordinalNumber}</span>
            <div className={'Content'}>
                <span>
                    {content}
                </span>
                <img
                    src={stepStatusToIcon[validationStatus]}
                    alt={validationStatus}
                    width={24}
                    height={24}
                />
            </div>
        </div>
    )
}

const SectionHeader = ({content} : {content: string}) => {
    return (
        <div className={'SectionHeader'}>
            <span>{content}</span>
            <hr className={'InfoLine'} />
        </div>
    )
}

export default ApplicationForm;