import React, { useEffect } from 'react';
import {
    ChatBubble, ThumbUp, Visibility, Settings, Share as ShareIcon,
    Equalizer, AddCircle, Palette, FavoriteBorder, Favorite,
    ChevronRight, DragHandleOutlined, Whatshot
} from '@material-ui/icons';
import { IconButton, Button, Tooltip, Dialog, DialogContent, DialogActions } from '@material-ui/core';

import NotFound from '../NotFound';
import moment from 'moment-timezone';
import {Basic, Square, Circle} from './Template';

import './TitleHome.scss';

import { useEpisodes } from '../../Recoil/Title/Episodes';
import { updateTitle } from '../../Data/Title';

import { fn, isAgeAdult, } from '../../Functions';
import ThemeDecoration from './ThemeDecoration';
import ThemeDecorationCol from './ThemeDecorationCol';
import Slideup from '../../Component/Slideup';
import TitleDetail from './TitleDetail';
import Share from '../../Component/Share';
import LoadingCircle from '../../Component/LoadingCircle';
import { logBehavior } from '../../Data/Behavior';
import { readAnalyticsSubmitInfo } from '../../Data/AnalyticsSubmit';
import { readProfile } from '../../Data/Profile';
import { useTitles, useSubscribedTitleList } from '../../Recoil/Title/Titles';
import { useOwnershipTitleList } from '../../Recoil/Ownership/Ownership';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';
import { useSetRecoilState } from 'recoil';
import { useSession } from '../../Recoil/Session/Session';
import { getProductInfo } from '../../Data/Product';
import { currentSchoolCompetitionYear, isOnSubmission, isUnderReview } from '../../Route/Analysis/Univ2024/Data/Variables';
import ChannelService from '../../Component/ChannelService';

const schoolCompetitionStateInfo = {
    complete: {
        color: 'rgba(61, 192, 97, 1)',
        text: "접수 완료",
        backgroundColor: 'rgba(233, 255, 242, 1)'
    },
    underReview: {
        color: 'rgba(37, 89, 244, 1)',
        text: "심의 중",
        backgroundColor: 'rgba(242, 249, 255, 1)'
    },
    reject: {
        color: 'rgba(235, 81, 71, 1)', 
        text: "반려",
        backgroundColor: 'rgba(255, 242, 242, 1)', 
    },
    none : {
        color: "", text:"", backgroundColor: "",
    }
}

const TitleHome = ({serviceId, titleId}) => {
    const alert = fn.alert;
    const { session } = useSession();

    if (!window.location.pathname.split('/').reverse()[0].includes('edit')) {
        localStorage.setItem('previousurl', window.location.pathname);
    };

    const { subscribe, unsubscribe, fetch: fetchSubscribed, isSubscribing } = useSubscribedTitleList();
    const { titleObject, isNotExist, fetch: fetchTitle } = useTitles();
    const { fetch: fetchOwnership, getOwnership } = useOwnershipTitleList();
    const { fetch: fetchEpisodes, episodeObject } = useEpisodes();

    const [ title, setTitle ] = React.useState(window.predef_data && window.predef_data.titleInfo ? window.predef_data.titleInfo : {});
    const [ titleFromServer, setTitleFromServer ] = React.useState(window.predef_data && window.predef_data.titleInfo ? window.predef_data.titleInfo : {});
    
    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        setPageHeader({titleText: title.title || "오늘의웹툰", isTitleHidden: true, isBackable: true,
        headerStyle: {backgroundColor: window.innerWidth<700&&title.themeTemplate!=='basic'?title.themeColor:'rgb(255,255,255)', maxWidth: 'none', margin: 0},
        mobileShortcuts: <>
            <IconButton >
                <Share title={title.title} contents={<ShareIcon />} log={{ what: 'share-title', detail: {serviceId: title.serviceId, titleId: title.titleId} }} />
            </IconButton>
        </>})
        ChannelService.ChannelIO();
    },[setPageHeader, title])

    const [ isLoading, setIsLoading ] = React.useState(false);
    const [ episodes, setEpisodes ] = React.useState([]);
    const [ sorting, setSorting ] = React.useState('ascending');
    const [ isNotFound, setIsNotFound ] = React.useState(false);
    const [ isAuthor, setIsAuthor ] = React.useState( false );
    const [ isThemeEditing, setIsThemeEditing ] = React.useState(false);
    const [ isFavoriteTitle, setIsFavoriteTitle ] = React.useState(false);
    const [ isDetailOpen, setIsDetailOpen ] = React.useState(false);
    const [ DialogWarningMessage , setDialogWarningMessage ] = React.useState(null);
    const [ isAllowedToApply, setIsAllowedToApply] = React.useState(false);
    const [ paymentConfirm, setPaymentConfirm ] = React.useState(false);
    const [ productInfo, setProductInfo ] = React.useState({amount: 1, finalAmount: 0});
    const [ paymentTargetEpisodeId, setPaymentTargetEpisodeId ] = React.useState(undefined);

    const [ submitInfos, setSubmitInfos ] = React.useState({});
    
    const [ openMessage, setOpenMessage ] = React.useState(null);
    const [ episodeIdForAuthentication, setEpisodeIdForAuthentication ] = React.useState(false);

    React.useEffect(()=>{
        setIsNotFound(!title && isNotExist(serviceId,titleId))
    },[isNotExist, serviceId, title, titleId])

    React.useEffect(()=>{
        if(!serviceId || !titleId){
            setIsNotFound(true);
            setIsLoading(false);
            return;
        }

        setIsLoading(isLoading => isLoading && !(`${serviceId}:${titleId}` in titleObject))
        
        if (`${serviceId}:${titleId}` in titleObject){
            setTitle(titleObject[`${serviceId}:${titleId}`]);
            setTitleFromServer(titleObject[`${serviceId}:${titleId}`]);
        }
    },[serviceId, titleId, titleObject]);

    React.useEffect(()=>{
        setIsFavoriteTitle(isSubscribing(serviceId, titleId));
    },[serviceId, titleId, isSubscribing])

    React.useEffect(()=>{
        
        if (getOwnership(serviceId, titleId) === 'owner') {
            setIsAuthor(true);
        } else {
            setIsAuthor(false);
        };
    },[getOwnership, serviceId, titleId])

    React.useEffect(()=>{
        fetchTitle([{serviceId, titleId}]);
        fetchEpisodes({serviceId, titleId});
        
        fetchSubscribed();
        fetchOwnership();

        ( async () => {
            let submitInfo = await readAnalyticsSubmitInfo({serviceId, titleId});
            setSubmitInfos(submitInfo);
            
        })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId])
    
    let schoolCompetitionState = "none";

    if ( submitInfos.schoolCompetitionSubmittedYear !== currentSchoolCompetitionYear ) {
        schoolCompetitionState = "none";
    }else if ( submitInfos.isSchoolCompetitionSubmitted && submitInfos.isAnalyticsValidatedAt ) {
        schoolCompetitionState = "complete";
    } else if ( submitInfos.isSchoolCompetitionSubmitted ) {
        schoolCompetitionState = "underReview";
    } else if ( submitInfos.isSchoolCompetitionModifiable ) {
        schoolCompetitionState = "reject";
    } else {
        schoolCompetitionState = "none";
    }

    useEffect(()=>{
        
        let episodesResult = episodeObject[`${serviceId}:${titleId}`] || [];

        if (episodesResult.length > 0) {
            setEpisodes(episodesResult);
        } else {
        };
    },[episodeObject, serviceId, titleId])

    React.useEffect(()=>{
        if (!session.userid){
            return;
        }
        (async()=>{
            const result = await getProductInfo(`${serviceId}:${titleId}`);
            setProductInfo({amount: 1, finalAmount: 0, ...result});
        })()
    },[serviceId, titleId, session])

    const [ isReaderButtonTooltipOn, setIsReaderButtonTooltipOn] = React.useState(false);

    React.useEffect(()=>{

        let timer = setTimeout( () => setIsReaderButtonTooltipOn(false) , 1000 );
        return ()=>{ 
            clearTimeout(timer) 
        }

    }, [isReaderButtonTooltipOn]);

    if(isNotFound){
        return (
        <div className={'OverviewArea'}>
            <div className={'BodyContainer'}>
                <NotFound />
            </div>
        </div>
        );
    }

    const [keywordsStr, ] = (title.keywords || '').split(':');
    const keywordsArr = keywordsStr.split(',');
    const tagsArr = (title.tags || '').split(',');
    
    const genreAndKeywords = [
        ...tagsArr,
        ...keywordsArr
    ].filter( (keyword, index) => keyword.length > 0 && [...tagsArr, ...keywordsArr].indexOf(keyword) === index );
    const applyingAnalysisDialogClose = () => {
        if (isAllowedToApply) {
            fn.goto(`/analysis/apply/${serviceId}/${titleId}`)
        } else {
            setDialogWarningMessage(null);
        };
    };

    const modalCloser = () => {
        setOpenMessage(null);
    };  

    const checkName = (str) => {
        //name의 마지막 음절의 유니코드(UTF-16) 
        const charCode = str.charCodeAt(str.length - 1);
        
        //유니코드의 한글 범위 내에서 해당 코드의 받침 확인
        const consonantCode = (charCode - 44032) % 28;
        
        if(consonantCode === 0){
            //0이면 받침 없음 -> 를
            return `를`;
        }
        //1이상이면 받침 있음 -> 을
        return `을`;
    }
    
    const isThisYearSchoolCompetition   = submitInfos.schoolCompetitionSubmittedYear === currentSchoolCompetitionYear;
    
    const schoolCompetitionInfo = schoolCompetitionStateInfo[schoolCompetitionState];
    const isSubmittedWork       = submitInfos.isSchoolCompetitionSubmitted || submitInfos.isSchoolCompetitionModifiable;
    const isShowState           = isOnSubmission || isUnderReview;
    
    return(
        <div className={'OverviewArea'}>
            {isShowState && isThisYearSchoolCompetition && isAuthor &&
                <div className={'SchoolCompetitionStateBar'} style={{backgroundColor: schoolCompetitionInfo.backgroundColor}} >
                    <span className={'TrafficBall'} style={{backgroundColor: schoolCompetitionInfo.color}} />
                    <span className={'Text'} style={{color: schoolCompetitionInfo.color}}>{schoolCompetitionInfo.text}</span>
                </div>}
            {isThemeEditing && <div className={`PCHeader`}>
                <div className={"ButtonContainer"}>
                    <div className={"OutlineButton Button"} onClick={()=>{
                        setTitle(titleFromServer);
                        setIsThemeEditing(false);
                    }}>
                        {"취소"}
                    </div>
                    <div className={"FilledButton Button"} onClick={()=>{
                        (async()=>{
                            let res = await updateTitle(title);
                            if (!res){
                                alert("테마 저장에 실패했습니다.");
                            }else{
                                setTitleFromServer(title);
                            }
                            setIsThemeEditing(false);
                        })();
                    }}>
                        {"저장"}
                    </div>
                </div>
                <div className={"Headline"}>
                    <div>{"작품 홈 꾸미기"}</div> 
                </div>
            </div>}
            <div className={'BodyContainer'}>
                <div className={`TitleInfoContainer`} style={{backgroundColor: 
                    title.themeTemplate === 'basic'
                        ?'rgb(250,250,250)'
                    :title.themeColor === 'rgb(255,255,255)'
                        ?'rgb(250,250,250)'
                        :title.themeColor
                    }}
                >
                    <div className={'PCShareButton'} >
                        <Share contents={ <ShareIcon />} pcStyle={{margin: 0}} log={{ what: 'share-title', detail: {serviceId: title.serviceId, titleId: title.titleId} }}/>
                    </div>
                    {title.themeTemplate === 'circle'?
                        <Circle title={title} isFavoriteTitle={isFavoriteTitle} />
                    :title.themeTemplate === 'basic'?
                        <Basic title={title} isFavoriteTitle={isFavoriteTitle} />
                    :title.themeTemplate === 'square'?
                        <Square title={title} isFavoriteTitle={isFavoriteTitle} />
                    :<></>}
                    <div className={`ActionsContainer ${title.themeColor === 'rgb(255,255,255)'?'WhiteContainer':''}`}  style={{backgroundColor: 
                        title.themeTemplate === 'basic'
                            ?'rgb(250,250,250)'
                        :title.themeColor === 'rgb(255,255,255)'
                            ?'rgb(250,250,250)'
                            :title.themeColor
                        }} 
                    >
                        <div className={"Actions"}>
                            <div className={'TitleInfomation'} >
                                <div className={'LinkToTitle'} onClick={()=>setIsDetailOpen(true)} >
                                    {'작품 정보'}
                                    <ChevronRight fontSize={'small'} />
                                </div>
                                <div className={'Description'} >
                                    {title.description}
                                </div>
                                <div className={'GenreKeywordTags'} >
                                    {genreAndKeywords
                                        .map( tag => (
                                            <span className={'Tag'} key={tag} onClick={ () => fn.goto(`/search?query=${tag}&type=tags&limit=10`) } >
                                                {"#" + tag}
                                            </span>
                                        ))
                                    }
                                </div>
                            </div>
                            {true && isAuthor
                                ?<div className={'AuthorArea'} style={{marginTop: 25}}>
                                    <div className={`AuthorInner`}>
                                        <div className={'ButtonBox'}>
                                            <Button onClick={()=>{
                                                const lastEpisode = episodes.length>0?episodes[episodes.length - 1]:null
                                                if (lastEpisode){
                                                    fn.goto(`/enroll/${serviceId}/${titleId}/${Number(lastEpisode.episodeId) + 1}`)
                                                }else {
                                                    fn.goto(`/enroll/${serviceId}/${titleId}/1`)
                                                }
                                            }} >
                                                <span className={'Icon'} >
                                                    <AddCircle fontSize={'small'}/>
                                                </span>
                                                <span >{'새 회차 등록'}</span>
                                            </Button>
                                        </div>
                                        <div className={'ButtonBox'}>
                                            <Button onClick={ () => {
                                                fn.goto(`/enroll/${serviceId}/${titleId}/edit`) 
                                            }}>
                                                <span className={'Icon'} >
                                                    <Settings fontSize={'small'}/>
                                                </span>
                                                <span >{'작품 관리'}</span>
                                            </Button>
                                        </div>
                                        <div className={'ButtonBox'}>
                                            <Button onClick={ () => {
                                                setIsThemeEditing(true)
                                            }} >
                                                <span className={'Icon'} >
                                                    <Palette fontSize={'small'}/>
                                                </span>
                                                <span >{'홈 꾸미기'}</span>
                                            </Button>
                                        </div>
                                        {!isSubmittedWork && false &&  // 운영종료
                                        <div className={'ButtonBox'}>
                                            <Button onClick={ async () => {
                                                setIsLoading(true);
                                                setIsAllowedToApply(false);
                                                
                                                await logBehavior('read-analyticsSubmit', submitInfos)
                                                
                                                if (submitInfos.isSchoolCompetitionSubmitted) {
                                                    //TODO 나중에 설문조사 2023년 설문조사로 수정
                                                    // if (isCeremoneyEnd) {
                                                    //     let didSurvey = await checkSurveyResponseExists('2022univ');
                                                    //     const univReports = await readUnivReport({serviceId, titleId})
                                                    //     const latestReport = univReports.sort( (a,b) => a.updatedAt - b.updatedAt)[0]
    
                                                    //     if (didSurvey?.isResponseExists) {
                                                    //         // 이미 설문을 했으므로 리포트 페이지로 이동
    
                                                    //         let {serviceId, titleId, episodeId, snapshotId} = latestReport;
                                                    //         if (serviceId && titleId && episodeId && snapshotId) {
                                                    //             fn.goto(`/report/${serviceId}/${titleId}/${episodeId}/${snapshotId}`)
                                                    //         } else {
                                                    //             setDialogWarningMessage(`보고서 로딩을 실패했습니다.
                                                    //             문의하기를 이용해주세요.`);
                                                    //         }
                                                    //         setIsLoading(false);
                                                    //         return;
                                                    //     } else {
                                                    //         // 보고서가 나온 과제 부활전 & 리디렉트 값을 달고 설문조사 페이지로 이동 
                                                            
                                                    //         fn.goto(`/survey/2022univ?redirectTo=${encodeURIComponent(`/report/${latestReport.serviceId}/${latestReport.titleId}/${latestReport.episodeId}/${latestReport.snapshotId}`)}`)
                                                    //         return;
                                                    //     };
                                                    // } else {
                                                    //     // 과제 부활전 작품들은 데이터 분석 신청 반려
                                                    //     setDialogWarningMessage(`과제 부활전 접수작품은 공모전 마감 후
                                                    //     데이터 분석이 진행됩니다.`)
                                                    // };

                                                } else if (!submitInfos.isAnalyticsSubmitted && !submitInfos.isCompetitionSubmitted) {
                                                    // 반려된 작품일 경우
                                                    if ( submitInfos.isSchoolCompetitionModifiable ) {
                                                        setDialogWarningMessage(`반려된 작품이에요 수정한 후 다시 신청해주세요.`);
                                                    } else {
                                                    // 분석 신청을 한적이 없는 유저
                                                        fn.goto(`/analysis/apply/${serviceId}/${titleId}`)
                                                    }
                                                } else if (submitInfos.isAnalyticsSubmitted && !submitInfos.isCompetitionSubmitted) {
                                                // 데이터 분석 접수(디렉터 피드백)

                                                    // 데이터 분석 신청만 한 사람, 광고 집행 전  
                                                    if (!submitInfos.isAnalyticsValidatedAt && !submitInfos.isAutomatedAnalyticsCompleted) {
                                                        setDialogWarningMessage(`이미 데이터 분석을 신청한 작품이네요! 
                                                        수정사항이 있는 경우에는 문의하기를 이용해주세요.`)
                                                        
                                                    // 광고 집행 중
                                                    } else if (submitInfos.isAnalyticsValidatedAt && !submitInfos.isAnalyticsCompleted){
                                                        setDialogWarningMessage(`데이터분석 진행 중인 작품이에요! 
                                                        열심히 보고서를 만드는 중이니 
                                                        조금만 기다려주세요 :)`)

                                                    // 데이터 분석(광고) 완료, 피드백 완료 => 리포트 페이지 이동
                                                    } else if (submitInfos.isAnalyticsCompleted && submitInfos.isAnalyticsValidatedAt) {
                                                        const keyToAddress = '/report/' + (submitInfos.key).split(':').join('/')
                                                        fn.goto(keyToAddress);
                                                    } else {
                                                        setDialogWarningMessage(`데이터 분석 신청에 실패했어요. 
                                                        문의하기를 이용해주세요.`)
                                                    };

                                                } else if (submitInfos.isSchoolCompetitionSubmitted && !submitInfos.isAnalyticsSubmitted) {
                                                // 창작 공모전 접수

                                                    // 공모전 접수만 한 사람, 광고 집행 전  
                                                    if (!submitInfos.isAutomatedAnalyticsCompleted && !submitInfos.isAnalyticsValidatedAt) {
                                                        setIsAllowedToApply(true);
                                                        setDialogWarningMessage(`이미 데이터 분석을 신청한 작품이네요! 
                                                        수정사항이 있는 경우에만 재신청해주세요.`)

                                                    // 광고 집행 중
                                                    } else if (submitInfos.isAnalyticsValidatedAt && !submitInfos.isAutomatedAnalyticsCompleted){
                                                        setDialogWarningMessage(`데이터분석 진행 중인 작품이에요! 
                                                        열심히 보고서를 만드는 중이니 
                                                        조금만 기다려주세요 :)`)

                                                    // 공모전 기초 분석(광고) 완료 => 리포트 페이지 이동
                                                    } else if (submitInfos.isAutomatedAnalyticsCompleted && submitInfos.isAnalyticsValidatedAt) {
                                                        const keyToAddress = '/report/' + (submitInfos.key).split(':').join('/')
                                                        fn.goto(keyToAddress);
                                                    } else {
                                                        setDialogWarningMessage(`공모전 신청에 실패했어요. 
                                                        문의하기를 이용해주세요.`)
                                                    }

                                                } else {
                                                    setDialogWarningMessage(`오류가 발생했어요. 
                                                    문의하기를 이용해주세요.`)
                                                }

                                                setIsLoading(false);
                                            }} >
                                                <span className={'Icon'}>
                                                    <Equalizer fontSize={'small'} />
                                                </span>
                                                <span >{`데이터 분석`}</span>
                                            </Button>
                                            <Dialog onClose={applyingAnalysisDialogClose} open={DialogWarningMessage?.length > 0 || false} >
                                                <DialogContent >
                                                    <div style={{width: '100%', height: '100%', maxWidth: 310, whiteSpace: 'pre-line', wordBreak: 'keep-all', marginTop: 10, marginBottom: 20, lineHeight: '24px'}}>
                                                        {DialogWarningMessage}
                                                    </div>
                                                </DialogContent>
                                                <DialogActions style={{ padding: '0 32px 16px 32px', display: 'flex', justifyContent: 'center'}} >
                                                    <Button autoFocus color={'primary'} onClick={applyingAnalysisDialogClose} >
                                                        {'확인'}
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </div>}
                                        {  !submitInfos.isAnalyticsSubmitted
                                        && !submitInfos.isSchoolCompetitionSubmitted
                                        && !submitInfos.isSchoolCompetitionModifiable
                                        && isOnSubmission
                                        && false // 운영 종료
                                            ?<div className={'ButtonBox'}>
                                                <Button onClick={ async () => {
                                                    setIsLoading(true);

                                                    if (submitInfos && submitInfos.isSchoolCompetitionSubmitted) {
                                                        if (isUnderReview) {
                                                            setDialogWarningMessage(`과제부활전 심사중입니다!
                                                            1월 15일 결과 발표를 기대해주세요.`)
                                                        } else if (isOnSubmission) {
                                                            setDialogWarningMessage(`이미 접수된 작품이에요.`)
                                                        };
                                                    } else if (submitInfos && submitInfos.isSchoolCompetitionModifiable) {
                                                        setDialogWarningMessage(`이메일로 안내드린 반려 사유를 확인하시고
                                                        작품을 수정해주세요.`)
                                                    } else {
                                                        fn.goto(`/analysis/univapply/${serviceId}/${titleId}`);
                                                    }

                                                    setIsLoading(false);
                                                }} >
                                                    <span className={'Icon'} >
                                                        <Whatshot fontSize={'small'}/>
                                                    </span>
                                                    <span >{'과제부활전'}</span>
                                                </Button>
                                            </div>:<></>}
                                    </div>
                                </div>
                            :true && !isAuthor
                                ?<div className={'ReaderArea'}>
                                    <div className={'ReaderButtonBox'} >
                                        <Tooltip placement={'bottom-start'} classes={{tooltipPlacementBottom: 'TitleHomeFavoriteTitle'}}
                                            arrow={true} open={isReaderButtonTooltipOn}
                                            title={isFavoriteTitle?
                                                <span style={{whiteSpace: 'pre-line', fontSize:'0.9rem', lineHeight: '24px'}}>
                                                    <span style={{fontWeight: 'bold'}}>{`관심작품`}</span>{`에 추가되었어요.
                                                    새 소식이 있으면 알려드릴게요.`}
                                                </span>
                                                :<span style={{fontSize:'0.9rem'}}>
                                                    <span style={{fontWeight: 'bold'}}>{`관심작품`}</span>
                                                    {'을 취소했습니다.'}
                                                </span>
                                            } >
                                            <Button onClick={ async () => {
                                                if (session.memberState !== 'regular') {
                                                    fn.goto('/signin')
                                                }

                                                if (isFavoriteTitle) {
                                                    const res = await unsubscribe(serviceId, titleId);
                                                    if (!res){
                                                        fn.alert("관심작품 취소에 실패했습니다.");
                                                    }else{
                                                        setIsFavoriteTitle(false);
                                                        setIsReaderButtonTooltipOn(true);
                                                    }
                                                } else {
                                                    const res = await subscribe(serviceId, titleId);
                                                    if (!res){
                                                        fn.alert("관심작품 등록에 실패했습니다.");
                                                    }else{
                                                        setIsFavoriteTitle(true);
                                                        setIsReaderButtonTooltipOn(true);
                                                    }
                                                }
                                            }}>
                                                {isFavoriteTitle
                                                    ?<Favorite style={{marginRight: 10}} fontSize={'small'} />
                                                    :<FavoriteBorder style={{marginRight: 10}}  fontSize={'small'}/>
                                                }
                                                <span >
                                                    {'관심 작품'}
                                                </span>
                                            </Button>
                                        </Tooltip>
                                        <Button style={{backgroundColor: 'rgb(3,3,3)', color: 'rgb(255,255,255)'}} onClick={ () => {
                                            const firstEpisodeId = episodes.sort((a,b) => a.episodeId - b.episdoeId )[0].episodeId
                                            fn.goto(`/contents/${serviceId}/${titleId}/${firstEpisodeId}`)
                                        }}>
                                            <span >{'첫편 보기'}</span>
                                        </Button>
                                    </div>
                                </div>
                            :!true
                                ?<div className={'ReaderArea'}>
                                    <div className={'ReaderButtonBox'} >
                                        <Button >
                                            <FavoriteBorder style={{marginRight: 10}}  fontSize={'small'}/>
                                            <span >
                                                {'관심 작품'}
                                            </span>
                                        </Button>
                                        <Button style={{backgroundColor: 'rgb(3,3,3)', color: 'rgb(255,255,255)'}} >
                                            <span >{'첫편 보기'}</span>
                                        </Button>
                                    </div>
                                </div>
                                :<></>}
                        </div>
                    </div>
                </div>
                {!isThemeEditing
                ?<div className={'EpisodeArea'}  >
                    <div className={'EpisodeHeader'} >
                        <div className={'MenuBar'} >
                            <div>
                                {`총 ${episodes.length}화`}
                            </div>
                            <div className={'SortingButtons'}>
                                <div 
                                    className={sorting === 'ascending'?'Focusing':'Basic'} 
                                    onClick={() => setSorting('ascending')} 
                                    size={'small'}
                                >
                                    {'첫화부터'}
                                </div>
                                <div className={'DividingLine'} >{'l'}</div>
                                <div 
                                    className={sorting === 'descending'?'Focusing':'Basic'} 
                                    onClick={() => setSorting('descending')} 
                                    size={'small'}
                                >
                                    {'최신화부터'}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'EpisodeBody'} >
                        <div className={'EpisodeList'} >
                            <div className={'EpisodeTable'} >
                                {episodes
                                    .sort((a,b)=> {
                                        if (sorting === "ascending") { return a.episodeId - b.episodeId }
                                        else if (sorting === "descending") { return b.episodeId - a.episodeId }
                                        return a.episodeId - b.episodeId
                                    })
                                    .map( ({episodeId, title, thumbnail, createdAt, viewCount, reactionCount, commentCount, isFree, purchase, purchaseAt}, index) => (
                                        <div className={isFree?`Free`:`NotFree`} key={`${serviceId}:${titleId}:${episodeId}`} >
                                            <Button
                                                fullWidth
                                                onClick={ async () => {
                                                    
                                                    let { memberState, email } = session;
                                                    let { is_adult } = titleFromServer;

                                                    if ( !isFree ) {
                                                        if(purchase){
                                                            fn.goto(`/contents/${serviceId}/${titleId}/${episodeId}`);
                                                        }else{
                                                            setPaymentTargetEpisodeId(episodeId);
                                                            setPaymentConfirm(true);
                                                        }
                                                    }else {
                                                        if ( is_adult ) {
                                                            //성인작품
                                                            setEpisodeIdForAuthentication(episodeId);
                                                            
                                                            if (email && memberState === 'regular') {
                                                                //로그인 유저
                                                                const res = await readProfile(session.userid);
                                                                if (res && res.certificatedBirthday) {
                                                                    //본인인증 && 성인 여부
                                                                    if ( isAgeAdult(res.certificatedBirthday) ) {
                                                                        // 본인인증이 된 유저 (성인)
                                                                        fn.goto(`/${isAuthor?'enroll':'contents'}/${serviceId}/${titleId}/${episodeId}`);
                                                                    } else {
                                                                        // 본인인증이 된 유저 (비성인)
                                                                        setOpenMessage('성인작품은 성인인증이 필요해요.')
                                                                    };
                                                                } else {
                                                                    // 본인인증이 되어있지 않은 유저
                                                                    setOpenMessage('성인작품은 성인인증이 필요해요.')
                                                                }
                                                            } else {
                                                                //비로그인 유저
                                                                setOpenMessage('성인작품은 성인인증이 필요해요.')
                                                            }
                                                        } else {
                                                            //일반작품
                                                            fn.goto(`/contents/${serviceId}/${titleId}/${episodeId}`);
                                                        };
                                                    }
                                                    
                                                }}
                                                disableElevation
                                                style={{justifyContent: 'flex-start'}}
                                                key={`Episode:${serviceId}:${titleId}:${episodeId}`}
                                            >
                                                <div className={`EpisodeRow`} key={`${titleId}:${episodeId}`} >
                                                    {!thumbnail
                                                        ?<img src={'https://static.webtoon.today/noEpisode.jpg'} alt={'thumbnail'} style={{width: 126, height: 75, objectFit: 'cover'}} />
                                                        :<img src={thumbnail} alt={'thumbnail'} style={{width: 126, height: 75, objectFit: 'cover'}} />
                                                    }
                                                    <div className={'TextCell'} >
                                                        <div className={'EpisodeTitle'} >
                                                            {(title || '')}
                                                        </div>
                                                        {isAuthor &&
                                                            <div style={{display: 'flex', color: 'rgb(151,151,151)'}}>
                                                                <span style={{marginRight: 16, display: 'flex', alignItems: 'center', fontSize:'0.9rem'}}>
                                                                    <Visibility style={{marginRight: 5, width: 15}} />
                                                                    <span>{viewCount}</span>
                                                                </span>
                                                                <span style={{marginRight: 16, display: 'flex', alignItems: 'center', fontSize:'0.9rem'}}>
                                                                    <ThumbUp style={{marginRight: 5, width: 15}} />
                                                                    {reactionCount}
                                                                </span>
                                                                <span style={{marginRight: 16, display: 'flex', alignItems: 'center', fontSize:'0.9rem'}}>
                                                                    <ChatBubble style={{marginRight: 5, width: 15}} />
                                                                    {commentCount}
                                                                </span>
                                                            </div>}
                                                        <div className={'CreatedTime'} style={{display:'flex', flexWrap: 'wrap', columnGap: "5px"}} >
                                                            {moment(createdAt * 1000).format('YY.MM.DD')}
                                                            {(!isFree&&!purchase)?
                                                                <div style={{color: "rgb(219,0,0)"}}>{"결제 필요"}</div>
                                                            :(!isFree&&purchase)?
                                                                <div style={{color: "rgb(0,176,240)"}}>{`결제: ${moment(purchaseAt).format('YY.MM.DD')}`}</div>
                                                            :<></>}
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </Button>
                                            {isAuthor && <div className={'EditButtonBox'}>
                                                <Button 
                                                    className={'EditButton'} 
                                                    onClick={ () => fn.goto(`/enroll/${serviceId}/${titleId}/${episodeId}/edit`)}
                                                >
                                                    <img src={'https://static.webtoon.today/ddah/icon/edit_fan_fill.svg'} alt={'fill_icon'} />
                                                </Button>
                                            </div>}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <Dialog open={(openMessage || '').length > 0} onClose={modalCloser} >
                        <div style={{display: 'flex', flexDirection: 'column', minWidth: 300, fontFamily: 'inherit'}}>
                            <div style={{ padding: '15px 20px', marginBottom: 30 }} >
                                <div>
                                    {openMessage}
                                </div>
                            </div>
                            <div style={{padding: '15px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Button
                                    onClick={modalCloser} 
                                    style={{  color: 'rgb(51,51,51)', padding: '7px 20px'}}>
                                    {'다음에'}
                                </Button>
                                <Button
                                    onClick={()=>{
                                        fn.goto(`/contents/${serviceId}/${titleId}/${episodeIdForAuthentication}`);
                                        modalCloser();
                                        setEpisodeIdForAuthentication(null);
                                    }} 
                                    style={{ backgroundColor: 'rgb(0,176,240)', color: 'rgb(255,255,255)', padding: '7px 20px'}}>
                                    {'확인'}
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog open={paymentConfirm} onClose={()=>setPaymentConfirm(false)}>
                        <div style={{display: 'flex', flexDirection: 'column', minWidth: 300, fontFamily: 'inherit'}}>
                            <div style={{ padding: '15px 20px'}} >
                                {productInfo && <div>
                                    {`유료분 총 ${episodes.filter(row=>!row.isFree).length} 회차를 `}<span style={{color:"red"}}>{`${Math.floor((productInfo.amount-productInfo.finalAmount)/productInfo.amount*100)}% 할인`}</span>{`된 가격에 <${productInfo.name}>${checkName(productInfo.name || "")} 판매하고 있어요!`}
                                </div>}
                                <div>
                                    {"구매하시겠어요?"}
                                </div>
                            </div>
                            <div style={{padding: '15px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <Button
                                    onClick={()=>setPaymentConfirm(false)} 
                                    style={{  color: 'rgb(51,51,51)', padding: '7px 20px'}}>
                                    {'다음에'}
                                </Button>
                                <Button
                                    onClick={()=>{
                                        fn.goto(`/payment/contents/${serviceId}/${titleId}/${paymentTargetEpisodeId}`);
                                        setPaymentConfirm(false);
                                    }} 
                                    style={{ backgroundColor: 'rgb(0,176,240)', color: 'rgb(255,255,255)', padding: '7px 20px'}}>
                                    {'확인'}
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                </div>
                :<div className={'ThemeEditForPC'}>
                    <ThemeDecorationCol
                        themeColor={title.themeColor}
                        setThemeColor={newColor=>setTitle({...title, themeColor: newColor})}
                        
                        themeTemplate={title.themeTemplate}
                        setThemeTemplate={newTemplate=>setTitle({...title, themeTemplate: newTemplate})}

                        saveTheme={()=>{
                            (async()=>{
                                let res = await updateTitle(title);
                                if (!res){
                                    alert("테마 저장에 실패했습니다.");
                                }else{
                                    setTitleFromServer(title);
                                }
                                setIsThemeEditing(false);
                            })();
                        }}
                        resetTheme={()=>{
                            setTitle(titleFromServer);
                            setIsThemeEditing(false);
                        }}
                    />
                </div>}
            </div>
            <div className={'ThemeEditForMobile'}>
                <ThemeDecoration
                    themeColor={title.themeColor}
                    setThemeColor={newColor=>setTitle({...title, themeColor: newColor})}
                    
                    themeTemplate={title.themeTemplate}
                    setThemeTemplate={newTemplate=>setTitle({...title, themeTemplate: newTemplate})}

                    open={isThemeEditing}
                    setOpen={setIsThemeEditing}

                    saveTheme={()=>{
                        (async()=>{
                            let res = await updateTitle(title);
                            if (!res){
                                alert("테마 저장에 실패했습니다.");
                            }else{
                                setTitleFromServer(title);
                            }
                            setIsThemeEditing(false);
                            document.body.classList.add('ScrollableThemeDecoration')
                            document.body.classList.remove('PreventingScrollInThemeDecoration')
                        })();
                    }}
                    resetTheme={()=>{
                        setTitle(titleFromServer);
                        document.body.classList.add('ScrollableThemeDecoration')
                        document.body.classList.remove('PreventingScrollInThemeDecoration')
                    }}
                />

            </div>
            <Slideup
                open={isDetailOpen}
                setOpen={setIsDetailOpen}
                handleChild={<DragHandleOutlined style={{color:'rgb(192,192,192)'}}/>}
            >
                <TitleDetail title={title}/>
            </Slideup>
            <LoadingCircle show={isLoading} />
        </div>
    )
}

export default TitleHome;